import { createReducer, on } from '@ngrx/store';
import { FALLiteSelectedFilters } from 'src/app/shared/models/v2/search';
import * as FalLiteActions from './falLite.action';

export const falliteFeatureKey = 'fal-lite';

export interface FalLiteState {
  activeFilters: boolean[];
  fALLiteSelectedFilters: FALLiteSelectedFilters | undefined;
}

export const initialState: FalLiteState = {
  activeFilters: [],
  fALLiteSelectedFilters: undefined
};

export const falLiteReducer = createReducer(
  initialState,
  on(FalLiteActions.loadFalLite, (state): FalLiteState => state),
  on(FalLiteActions.loadFalLiteSuccess, (state): FalLiteState => state),
  on(FalLiteActions.loadFalLiteFailure, (state): FalLiteState => state),
  on(FalLiteActions.setFalLiteSelectedFilters, (state, { selectedFilters }): FalLiteState => {
    return { ...state, fALLiteSelectedFilters: selectedFilters };
  }),
  on(FalLiteActions.selectedFilterStateReset, (state): FalLiteState => {
    return { ...state, fALLiteSelectedFilters: undefined };
  })
);
