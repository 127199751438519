import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HomeEffects } from './home.effects';
import * as fromHome from './home.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(fromHome.homeFeatureKey, fromHome.homeReducer), EffectsModule.forFeature([HomeEffects])],
  declarations: []
})
export class HomeStateModule {}
