import { SearchUI } from './../../../shared/models/ui/searchui';
import { Location, mockLocation, SearchResult, SelectedGeolocation } from './../../../shared/models/v2/search';
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as SearchActions from './search.actions';
export const SEARCH_FEATURE_KEY = 'search';

export interface SearchState extends EntityState<SearchResult> {
  id: string; // which Location record has been selected
  loaded: boolean; // has the Location list been loaded
  searchTerms: string; // last search terms entered by user.
  searchRoute: string; // Last route used to search the API.
  error?: string | null; // last known error (if any)
  activeFilters: boolean[];
  results: Location[];
  apiLoaded: boolean;
  UI: SearchUI;
  pageSearchResult?: SearchResult;
  selectedGeolocation: SelectedGeolocation | null;
  locationsForPins: Location[];
}

export interface PartialState {
  readonly [SEARCH_FEATURE_KEY]: SearchState;
}
export const searchAdapter: EntityAdapter<SearchResult> = createEntityAdapter({
  selectId: (model: SearchResult) => model.id
});

export const initialState: SearchState = searchAdapter.getInitialState({
  // set initial required properties
  id: '',
  searchRoute: '',
  searchTerms: '',
  loaded: false,
  activeFilters: [],
  results: [],
  pageSearchResult: undefined,
  selectedGeolocation: null,
  onlineBooking: true,
  apiLoaded: true,
  UI: {
    scrollPosition: 0,
    isCardView: true,
    selectedLocation: mockLocation,
    filterValues: [],
    mapCenter: { lat: 47.22074, lng: -122.53377 },
    mapZoom: 8
  },
  locationsForPins: []
});

const searchReducer = createReducer(
  initialState,
  on(SearchActions.init, (SearchState): SearchState => ({ ...SearchState, loaded: true, error: null })),
  on(SearchActions.searchStarted, (SearchState, { searchTerms }): SearchState => ({ ...SearchState, searchTerms })),
  on(SearchActions.loadSearchSuccess, (SearchState, { search }) => searchAdapter.setOne(search, { ...SearchState, loaded: false })),
  on(SearchActions.loadSearchFailure, (SearchState, { error }): SearchState => ({ ...SearchState, error, loaded: false })),
  on(SearchActions.setApiLoaded, (SearchState, { apiLoaded }): SearchState => ({ ...SearchState, apiLoaded })),
  on(
    SearchActions.toggleCardView,
    (SearchState): SearchState => ({ ...SearchState, UI: { ...SearchState.UI, isCardView: !SearchState.UI.isCardView } })
  ),
  on(SearchActions.setMapCenter, (SearchState, { mapCenter }): SearchState => ({ ...SearchState, UI: { ...SearchState.UI, mapCenter } })),
  on(SearchActions.setMapZoom, (SearchState, { mapZoom }): SearchState => ({ ...SearchState, UI: { ...SearchState.UI, mapZoom } })),
  on(
    SearchActions.loadSearchNextPageSuccess,
    (SearchState, { search }): SearchState => ({ ...SearchState, pageSearchResult: search, loaded: false })
  ),
  on(
    SearchActions.setSelectedGeolocation,
    (SearchState, { selectedGeolocation }): SearchState => ({
      ...SearchState,
      selectedGeolocation: selectedGeolocation
    })
  ),
  on(SearchActions.searchStateReset, (): SearchState => ({ ...initialState, results: [] }))
);

export function reducer(SearchState: SearchState | undefined, action: Action): SearchState {
  return searchReducer(SearchState, action);
}
