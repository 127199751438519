import { v4 as uuid } from 'uuid';
import { Filter } from 'src/app/shared/models/filters';

export interface SearchResultModel {
  currentPageNumber: string;
  isMoreDataAvailable: boolean;
  pageSize: string;
  pages: number;
  searchTerm: string;
  totalResults: number;
}

export interface SearchResult {
  id: string;
  searchTerm: string;
  currentPageNumber: number;
  pageSize: number;
  pages: number;
  totalResults: number;
  isMoreDataAvailable: boolean;
  errors: Error[];
  sortBy: SortBy;
  filters: Filter;
  results: Location[];
  SearchResult?: SearchResultModel;
  locationForMapPins: Location[];
  recommendations: [];
}

export interface Error {
  statusCode: string;
  message: string;
}

export interface Comparison {
  operator: string;
  value: string;
}

export interface Location {
  id: number;
  type: string;
  name: string;
  phoneNumber: string[];
  acceptingNewPatients: boolean;
  onlineBooking: boolean;
  distance: number;
  faxNumber: string[];
  website: string;
  email: string;
  aboutLocation: string;
  locationAvailability: string;
  videoURLs: string[];
  headerImage: LocationHeaderImage[];
  services: LocationSpecialty[] | null;
  nearByLocationMarkers: NearByLocationMarker[];
  locationHours: LocationHour[];
  address: Address;
  associatedProviders: AssociatedProvider[];
  busRouteInformation: string[];
  parkingInformation: string[];
  additionalHours: string[];
  directions: string[];
  meta: Meta[];
  tollFreeNumber?: string[];
  hospitalVisitorRestrictions: string[];
  holidayHours?: string[];
  externalCode: string;
  emergencyNotifications?: string[];
}

export interface LocationHeaderImage {
  imageUrl: string[];
}

export interface LocationSpecialty {
  specialty: string;
  specialtyFullName: string;
  subSpecialty: string;
  subSpecialtyFullName: string;
  isPrimary: boolean;
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  zipCode: string;
  latitude?: number;
  longitude?: number;
}

export interface ProviderName {
  first: string;
  middle: string;
  last: string;
}

export interface AssociatedProvider {
  name: ProviderName;
  displayFullName: string;
  npi: string;
  acceptingNewPatients: boolean;
  specialties: Specialty[];
  image: string;
}
export interface FALLiteSelectedFilters {
  selectedLocations: string | undefined;
  selectedSpecialties: string | undefined;
  searchDistance: number;
}
export interface ProviderFromFAD {
  displayFullName: string;
  firstName: string;
  lastName: string;
  npi: string;
  acceptingNewPatients: boolean;
  primarySpecialty: string;
  secondarySpecialty: string[];
  imageSmall: string;
  medicalGroupIds: string[];
  gender: string;
}

export interface Specialty {
  specialty: string;
  specialtyFullName: string;
  subSpecialty: string;
  subSpecialtyFullName: string;
  isPrimary: boolean;
}
export interface BusRouteInformation {
  details: string;
  routes: Route[];
}

export interface Route {
  transitType: string;
  url: string;
  title: string;
}

export interface LocationHour {
  id: number;
  day: string;
  openTime: string;
  closeTime: string;
  is24Hours: boolean | null;
  officeHourType: OfficeHourType | null;
}

export interface OfficeHourType {
  name: string;
  externalCode: number;
  id: number;
}

export interface Meta {
  key: string;
  value: string;
  delimiter: string;
}

export interface NearByLocationMarker {
  latitude: string;
  longitude: string;
}

export interface ParkingInformation {
  details: string;
  links: Link[];
}

export interface Link {
  url: string;
  title: string;
}

export interface SortBy {
  name: string;
  isDescending: boolean;
}

export interface navigateFAD {
  npi: string;
  locationId: string;
  locationName: string;
  city: string;
  state: string;
}

export const SessionConstants = {
  AllSpecialtiesFilter: 'allServicesFilter',
  SelectedSpecialtiesFilter: 'specialtiesFilter',
  AllLocationTypesFilter: 'allLocationTypesFilter',
  SelectedLocationTypesFilter: 'locationTypesFilter',
  SelectedRadiusFilter: 'radiusFilter',
  SelectedLocation: 'selectedLocation',
  ReplaceKeyword: 'replaceKeyword',
  ToggleCardView: 'toggleCardView',
  LocationFromRoute: 'locationFromRoute',
  LinkBackButtonUrl: 'linkBackButtonUrl'
};

export const mockLocation: Location = {
  id: 1212,
  externalCode: 'xzy28988-117c-4637-9d4f-01336234bxyz',
  name: 'Test Location - Phoenix',
  type: 'Clinic',
  phoneNumber: ['555-555-5555'],
  acceptingNewPatients: true,
  onlineBooking: true,
  distance: 10,
  faxNumber: ['555-555-5555'],
  website: 'www.test.com',
  email: 'test@test.com',
  aboutLocation: 'Testing.',
  locationAvailability: 'open',
  emergencyNotifications: ['<strong> Emergency Notifications </strong> this is an emergency'],
  hospitalVisitorRestrictions: [],
  videoURLs: ['https://www.youtube.com/watch?v=dQw4w9WgXcQ', 'https://www.youtube.com/watch?v=bmDdHk_X864'],
  headerImage: [
    {
      imageUrl: ['/assets/mock-hero.png']
    }
  ],
  services: [
    {
      specialty: 'XRay',
      specialtyFullName: 'XRay Full Name',
      subSpecialty: '',
      subSpecialtyFullName: 'CSH PNW Franciscan: XRay',
      isPrimary: false
    },
    {
      specialty: 'Testing',
      specialtyFullName: 'Testing Full Name',
      subSpecialty: '',
      subSpecialtyFullName: 'CSH PNW Franciscan: Testing',
      isPrimary: false
    },
    {
      specialty: 'Therapy',
      specialtyFullName: 'Therapy Full Name',
      subSpecialty: '',
      subSpecialtyFullName: 'CSH PNW Franciscan: Therapy',
      isPrimary: false
    }
  ],
  nearByLocationMarkers: [
    {
      latitude: '-79.8799',
      longitude: '-142.3381'
    },
    {
      latitude: '-65.1304',
      longitude: '50.5425'
    },
    {
      latitude: '11.7656',
      longitude: '61.9374'
    }
  ],
  locationHours: [
    {
      id: 101874,
      day: 'Friday',
      openTime: '07:30:00',
      closeTime: '18:00:00',
      is24Hours: null,
      officeHourType: {
        id: 0,
        name: 'standard',
        externalCode: 0
      }
    },
    {
      id: 101875,
      day: 'Thursday',
      openTime: '07:30:00',
      closeTime: '18:00:00',
      is24Hours: null,
      officeHourType: {
        id: 0,
        name: 'standard',
        externalCode: 0
      }
    },
    {
      id: 101876,
      day: 'Wednesday',
      openTime: '07:30:00',
      closeTime: '18:00:00',
      is24Hours: null,
      officeHourType: {
        id: 0,
        name: 'standard',
        externalCode: 0
      }
    },
    {
      id: 101877,
      day: 'Tuesday',
      openTime: '07:30:00',
      closeTime: '18:00:00',
      is24Hours: null,
      officeHourType: {
        id: 0,
        name: 'standard',
        externalCode: 0
      }
    },
    {
      id: 101878,
      day: 'Monday',
      openTime: '07:30:00',
      closeTime: '18:00:00',
      is24Hours: null,
      officeHourType: {
        id: 0,
        name: 'standard',
        externalCode: 0
      }
    }
  ],
  address: {
    addressLine1: '7210 40th Street W',
    addressLine2: '',
    addressLine3: '',
    city: 'University Place',
    state: 'WA',
    zipCode: '98466',
    latitude: 47.22074,
    longitude: -122.53377
  },
  associatedProviders: [
    {
      npi: '1295744555',
      displayFullName: 'DR FIRST MIDDLE LAST',
      name: {
        first: 'BRIAN',
        middle: 'J',
        last: 'BERRY'
      },
      acceptingNewPatients: true,
      specialties: [
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        }
      ],
      image: ''
    },
    {
      npi: '1295744555',
      displayFullName: 'DR FIRST MIDDLE LAST',
      name: {
        first: 'BRIAN',
        middle: 'J',
        last: 'BERRY'
      },
      acceptingNewPatients: true,
      specialties: [
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        }
      ],
      image: ''
    },
    {
      npi: '1295744555',
      displayFullName: 'DR FIRST MIDDLE LAST',
      name: {
        first: 'BRIAN',
        middle: 'J',
        last: 'BERRY'
      },
      acceptingNewPatients: false,
      specialties: [
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        },
        {
          specialty: 'primary specialty',
          specialtyFullName: 'Test full name',
          subSpecialty: 'string',
          subSpecialtyFullName: 'string',
          isPrimary: true
        }
      ],
      image: ''
    }
  ],
  busRouteInformation: ['<h2>Bus Routes</h2>'],

  parkingInformation: ['<h2>Parking</h2>'],

  directions: ['<h2>Directions</h2>'],

  additionalHours: ['<h2>Additional Hours</h2>'],

  meta: [
    {
      key: 'test meta',
      value: 'test meta value',
      delimiter: ','
    }
  ]
};

export const mockSearchResult: SearchResult = {
  id: uuid(),
  searchTerm: 'test',
  currentPageNumber: 0,
  pageSize: 20,
  pages: 40,
  totalResults: 800,
  isMoreDataAvailable: true,
  errors: [],
  sortBy: {
    name: 'type',
    isDescending: true
  },
  filters: {
    ServicesAvailable: [
      {
        item: [
          {
            value: 'Foo Service',
            count: 1
          },
          {
            value: 'Bar Service',
            count: 2
          }
        ]
      }
    ],
    acceptsNewPatients: [],
    locationType: [
      {
        item: [
          {
            value: 'Foo Location Type',
            count: 1
          },
          {
            value: 'Bar Location Type',
            count: 2
          }
        ]
      }
    ]
  },
  results: [mockLocation],
  locationForMapPins: [mockLocation],
  recommendations: []
};
export interface GeolocationCookieData {
  neighborhood: string;
  city: string;
  latitude: number;
  longitude: number;
  name: string;
  state: string;
  zip: string;
}

export interface SelectedGeolocation extends GeolocationCookieData {
  address: string;
  googlePlaceId: string;
  isLocationValid: boolean;
}

export interface KeywordSearchSuggestions {
  locations: LocationSuggestion[];
  services: string[];
  keywords: string[];
}

export interface LocationSuggestion {
  id: number;
  name: string;
}

export interface LocationSuggestionsResponse {
  term: string;
  items: string[];
}

export interface ApiResponse<T> {
  errors: string[];
  isValid: boolean;
  warnings: string[];
  result: T;
}

export interface GeolocationByIpResult {
  ip: string;
  location: GeolocationCookieData;
}

export interface GeolocationSuggestionResult {
  locationName: string;
  googlePlaceId: string;
  sessionToken: string;
}

export interface GeolocationDetailResult {
  neighborhood: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  name: string;
  sessionToken: string;
  state: string;
  zip: string;
}

export enum SearchType {
  AllPins = 'allpins',
  OnlyLocations = 'onlylocations'
}
