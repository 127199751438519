import { InjectionToken, Type } from '@angular/core';
import { AppComponent } from 'src/app/app/app.component';

export const FAL_ROOT_ELEMENT = new InjectionToken<HTMLElement>('FAL_ROOT_ELEMENT');

export const getRootElement = (): HTMLElement | undefined => {
  const falSaasElement = document.body.querySelector<HTMLElement>('fal-saas');
  if (falSaasElement) {
    return falSaasElement;
  }

  console.error('Unable to find the root element.');

  return undefined;
};

export const getBaseUrl = (rootElement: HTMLElement | undefined): string => {
  const regex = /^\/+|\/+$/g;

  // attempt to pull from custom root element attribute
  const baseUrlFromAttribute = (rootElement?.getAttribute('base-url') ?? rootElement?.getAttribute('baseUrl'))?.replace(regex, '');
  if (baseUrlFromAttribute) {
    return `/${baseUrlFromAttribute}`;
  }

  // attempt fallback to <base href="{path}" />
  const baseUrlFromBaseTagHref = document.head.querySelector<HTMLElement>('base')?.getAttribute('href')?.replace(regex, '');
  if (baseUrlFromBaseTagHref) {
    return `/${baseUrlFromBaseTagHref}`;
  }

  // default
  return '/';
};

export const FalAngularElements: [string, Type<any>][] = [['fal-saas', AppComponent]];

export const getEmbedSource = (): string | null | undefined => {
  const rootElement = getRootElement();
  return rootElement?.hasAttribute('embedSource')
    ? rootElement?.getAttribute('embedSource')
    : rootElement?.hasAttribute('embedsource')
    ? rootElement?.getAttribute('embedsource')
    : rootElement?.hasAttribute('embed-source')
    ? rootElement?.getAttribute('embed-source')
    : '';
};

export enum EmbedSource {
  CERNER = 'cerner',
  EPIC_MY_CHART = 'mychart',
  AEM = 'aem'
}
