import { Injectable } from '@angular/core';
import { FalEvent } from '../../models/fal-event';

@Injectable({
  providedIn: 'root'
})
export class EventPublisherService {
  private eventName = 'CommonSpirit:FindALocation';

  constructor() {
    // intentionally empty
  }

  publish(event: FalEvent): void {
    window.dispatchEvent(new CustomEvent(this.eventName, { detail: event }));
  }
}
