/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import * as HydrationActions from './hydration.actions';
import { Router } from '@angular/router';

@Injectable()
export class HydrationEffects implements OnInitEffects {
  hydrate$ = createEffect(() => {
    return this.action$.pipe(
      ofType(HydrationActions.hydrate),
      map(() => {
        const storageValue = localStorage.getItem('state');
        if (storageValue) {
          try {
            const state = JSON.parse(storageValue);
            return HydrationActions.hydrateSuccess({ state });
          } catch {
            localStorage.removeItem('state');
          }
        }
        return HydrationActions.hydrateFailure();
      })
    );
  });

  serialize$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(HydrationActions.hydrateSuccess, HydrationActions.hydrateFailure),
        switchMap(() => this.store),
        distinctUntilChanged(),
        tap((state: any) => {
          const { environment, home, location, search } = state;
          const serialized = {
            environment,
            home,
            location,
            search
          };
          localStorage.setItem('state', JSON.stringify(serialized));
        })
      );
    },
    { dispatch: false }
  );

  constructor(private action$: Actions, private store: Store, private router: Router) {}

  ngrxOnInitEffects(): Action {
    return HydrationActions.hydrate();
  }
}
