import { createAction, props } from '@ngrx/store';
import { AssociatedProvider, Location } from 'src/app/shared/models/v2/search';

export const init = createAction('[Location] Init');

export const loadLocations = createAction('[Location] Load Location', props<{ payload: Location[] }>());

export const setSelectedLocation = createAction('[Location] Select Location', props<{ payload: Location }>());

export const selectLocationById = createAction('[Location] Select Location', props<{ locationId: number }>());

export const setProvidersBySelectedLocation = createAction(
  '[Location] Select Providers by Selected Location',
  props<{ providers: AssociatedProvider[] }>()
);

export const unloadLocation = createAction('[Location] Unload Location', props<{ locationId: number }>());

export const loadLocationsSuccess = createAction('[Location] Load Location Success', props<{ data: Location }>());

export const loadLocationsFailure = createAction('[Location] Load Location Failure', props<{ error: any }>());

export const setRoutedFrom = createAction('[Location] Set Routed From', props<{ routedFrom: string }>());
