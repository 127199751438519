import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { InstanceService } from '@commonspirit/ngx-instance';
import { ConfigurationKey } from '../../models/configuration-key';
import { EmbedSource, getEmbedSource } from '../../util/root-element';

@Injectable({
  providedIn: 'root'
})
export class LocationDetailGuard implements CanActivate {
  redirectPages = false;

  constructor(public instanceService: InstanceService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.getConfiguration();
    if (this.redirectPages) {
      const locationNameUrlSegments = this.instanceService.getConfiguration(ConfigurationKey.LocationNameUrlSegments)?.value as string[];
      if (locationNameUrlSegments && locationNameUrlSegments.length > 0) {
        // get current location name url segment from route
        const locationNameFromRoute = route.url[0];
        if (locationNameFromRoute && locationNameFromRoute.path) {
          const locationNameUrlSegment = locationNameFromRoute.path.substring(0, locationNameFromRoute.path.lastIndexOf('-'));
          if (locationNameUrlSegments.includes(locationNameUrlSegment)) {
            const falPageUrl = this.instanceService.getConfiguration(ConfigurationKey.FalPage)?.value as { link: string };
            if (falPageUrl && falPageUrl.link) {
              // trigger HTTP redirect to allow AEM-based redirect rules to trigger
              window.location.href = `${falPageUrl.link}/${locationNameFromRoute.path}`;
              return false;
            }
            return true; // the FAL page URL setting is falsey
          }
          return true; // the location name URL segments setting does not include the current location
        }
        return true; // the location name route segment or its path property are falsey
      }
      return true; // location name URL segments setting is falsey or empty
    }
    return true; // not hosted in AEM
  }

  getConfiguration(): void {
    const embedSource = getEmbedSource();
    const isHostedAem = this.instanceService.getConfiguration(ConfigurationKey.AemRedirectPage)?.value as boolean;
    if (embedSource === EmbedSource.AEM) this.redirectPages = isHostedAem;
    else this.redirectPages = false;
  }
}
