/* eslint-disable @typescript-eslint/prefer-regexp-exec */
import { UrlMatchResult, UrlSegment } from '@angular/router';

/**
 * Matches a route for the location search results page.
 *
 * @param url UrlSegment to match on
 */
export function falSearchResultsVanityUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 0) {
    return null;
  }

  const searchTermRegex = /^t$/s;
  const locationRegex = /^l$/s;
  const locationAndSearchTermRegex = /^r$/s;

  // search term, e.g. /t/cancer
  if (url[0].path.match(searchTermRegex)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        searchTerm: getUrlSegmentOrEmptyUrlSegment(url, 1)
      }
    };
  }
  // location, e.g. /l/phoenix-az
  else if (url[0].path.match(locationRegex)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        geolocation: getUrlSegmentOrEmptyUrlSegment(url, 1)
      }
    };
  }
  // location + search term, e.g. /r/phoenix-az/cancer
  else if (url[0].path.match(locationAndSearchTermRegex)) {
    return {
      consumed: url,
      posParams: {
        searchType: url[0],
        geolocation: getUrlSegmentOrEmptyUrlSegment(url, 1),
        searchTerm: getUrlSegmentOrEmptyUrlSegment(url, 2)
      }
    };
  }
  return null;
}

/**
 * Matches a route for the location detail page.
 *
 * @param url UrlSegment to match on
 */
export function falLocationDetailVanityUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  /**
   * DO match on:
   * /mercy-health-123 : yes, id: 123
   *
   * DO NOT match on:
   * /location
   * /mercyhealth
   * /123
   * /location123
   */
  if (url.length === 0 || url[0].path.indexOf('-') < 1) {
    return null;
  }

  // extract location id and confirm it is an integer value
  const locationId = url[0].path.substring(url[0].path.lastIndexOf('-') + 1);
  if (isNaN(parseInt(locationId))) {
    return null;
  }

  return {
    consumed: url,
    posParams: {
      id: new UrlSegment(locationId, {})
    }
  };
}

function getUrlSegmentOrEmptyUrlSegment(urlSegments: UrlSegment[], index: number): UrlSegment {
  return urlSegments.length > index ? urlSegments[index] : new UrlSegment('', {});
}
