import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@commonspirit/ngx-environment';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { EnvironmentActionType } from '../../../core/+state/actions/environment.actions';
import { Location, SearchType } from '../../../shared/models/v2/search';
import {
  autoSuggestionLocation,
  geoLocationDetails,
  IProviderParams,
  ISearchParams,
  keywordDetails,
  ServiceDetails
} from '../../models/v1/location';
import { MockEnvironment } from './../../../../environments/mockenvironment';
import { ProviderFromFAD, SearchResult } from './../../models/v2/search';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public _endpoint = '';
  public _isLocalFile = false;
  public _fadEndpoint = '';

  constructor(private _http: HttpClient, private actionsSubject: ActionsSubject, private environmentService: EnvironmentService) {
    // ensure environmentService has loaded
    this.actionsSubject.pipe(ofType(EnvironmentActionType.LOAD)).subscribe(() => {
      const falApiEndpoint: string | undefined = this.environmentService.getEndpoint('mulesoftLocationsApi')?.url;
      const fadApiEndpoint: string | undefined = this.environmentService.getEndpoint('fadLocationApi')?.url;

      if (environment.useMockEnvironment) {
        this._endpoint = MockEnvironment.endpointURL;
      } else {
        if (!falApiEndpoint) {
          throw new Error('Locations api has not been set. Invalid Configuration.');
        }
        if (!fadApiEndpoint) {
          throw new Error('FAD api has not been set. Invalid Configuration.');
        }
        this._endpoint = falApiEndpoint;
        this._fadEndpoint = fadApiEndpoint;
      }

      try {
        new URL(this._endpoint);
        this._isLocalFile = false;
      } catch (_) {
        this._isLocalFile = true;
      }
    });
  }

  public query(searchParams: ISearchParams): Observable<SearchResult> {
    if (this._isLocalFile) {
      return this._http.get<SearchResult>(`${this._endpoint}searches.json`);
    }
    return this._http.get<SearchResult>(`${this._endpoint}/search`, { params: this.searchParamsToHttpParams(searchParams) });
  }

  public allMapPins(searchParams: ISearchParams): Observable<SearchResult> {
    if (this._isLocalFile) {
      return this._http.get<SearchResult>(`${this._endpoint}searches.json`);
    }
    return this._http.get<SearchResult>(`${this._endpoint}/map-pins`, { params: this.searchParamsToHttpParams(searchParams) });
  }

  public getById(id: string): Observable<Location> {
    if (this._isLocalFile) {
      return this._http.get<Location>(`${this._endpoint}locations.json`);
    }
    return this._http.get<Location>(`${this._endpoint}/details/${id}`);
  }

  private searchParamsToHttpParams(_searchParams: ISearchParams): HttpParams {
    let httpParams = new HttpParams();
    const searchTerm = _searchParams.searchTerm?.trim();
    const sortBy = _searchParams.sortBy?.toString().trim();
    const specialties = _searchParams.specialties?.trim();
    const locationType = _searchParams.locationType?.trim();
    const distance = _searchParams.distance?.toString().trim();
    const city = _searchParams.city?.toString().trim();
    const state = _searchParams.state?.toString().trim();
    const zipcode = _searchParams.zipcode?.toString().trim();
    const latitude = _searchParams.latitude?.toString().trim();
    const longitude = _searchParams.longitude?.toString().trim();
    const page = _searchParams.page?.toString().trim();

    if (searchTerm != null && searchTerm != undefined && searchTerm != '') httpParams = httpParams.set('searchTerm', searchTerm);
    if (sortBy != null && sortBy != undefined) httpParams = httpParams.set('sortBy', sortBy);
    if (specialties != null && specialties != undefined && specialties != '') httpParams = httpParams.set('specialties', specialties);
    if (locationType != null && locationType != undefined && locationType != '') httpParams = httpParams.set('locationType', locationType);
    if (distance != null && distance != undefined) httpParams = httpParams.set('distance', distance);
    if (city != null && city != undefined && city != '') httpParams = httpParams.set('city', city);
    if (state != null && state != undefined && state != '') httpParams = httpParams.set('state', state);
    if (zipcode != null && zipcode != undefined && zipcode != '') httpParams = httpParams.set('zipcode', zipcode);
    if (latitude != null && latitude != undefined) httpParams = httpParams.set('latitude', latitude);
    if (longitude != null && longitude != undefined) httpParams = httpParams.set('longitude', longitude);
    if (page != null && page != undefined) httpParams = httpParams.set('page', page);

    return httpParams;
  }

  public LoadAssociatedProvider(params: IProviderParams): Observable<ProviderFromFAD[]> {
    return this._http.get<any>(`${this._fadEndpoint}`, { params: this.setFadLocationHttpParams(params) }).pipe(
      map((result: any) => {
        const providerObj = JSON.parse(JSON.stringify(result)).result.providers;
        return providerObj;
      })
    );
  }

  private setFadLocationHttpParams(params: IProviderParams): HttpParams {
    return new HttpParams()
      .set('divisionCodes', params.divisionCode ? params.divisionCode.trim() : '')
      .set('marketCodes', params.marketCode ? params.marketCode.trim() : '')
      .set('locationIds', params.locationId ? params.locationId?.toString().trim() : '');
  }

  public getAutoSuggestionLocation(id: string): Observable<autoSuggestionLocation> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchQuery', encodeURIComponent(id));
    return this._http.get<autoSuggestionLocation>(`${this._endpoint}/suggestions/locations`, {
      params: httpParams
    });
  }

  public getGeoLocationCityState(id: string): Observable<geoLocationDetails> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchQuery', encodeURIComponent(id));
    return this._http.get<geoLocationDetails>(`${this._endpoint}/suggestions/location-detail`, {
      params: httpParams
    });
  }

  public getAutoSuggestionLocations(id: string): Observable<keywordDetails> {
    return this._http.get<keywordDetails>(`${this._endpoint}/autocomplete/locations?term=${id}`);
  }

  public getAutoSuggestionServices(id: string): Observable<ServiceDetails> {
    return this._http.get<ServiceDetails>(`${this._endpoint}/suggestions/services?term=${id}`);
  }

  public getAutoSuggestionKeywords(id: string): Observable<ServiceDetails> {
    return this._http.get<ServiceDetails>(`${this._endpoint}/suggestions/keywords?term=${id}`);
  }
}
