/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import * as uuid from 'uuid';
import { RouterEvent } from '@angular/router';
import { FalEventTypes } from './fal-event-types';

export class FalEvent {
  /**
   * [REQUIRED] Identifies the event. Producers MUST ensure that `source` + `id`
   * is unique for each distinct event. If a duplicate event is re-sent (e.g. due
   * to a network error) it MAY have the same `id`. Consumers MAY assume that
   * Events with identical `source` and `id` are duplicates.
   * @required Non-empty string. Unique within producer.
   * @example An event counter maintained by the producer
   * @example A UUID
   */
  id: string;

  /**
   * [REQUIRED] The version of the CloudEvents specification which the event
   * uses. This enables the interpretation of the context. Compliant event
   * producers MUST use a value of `1.0` when referring to this version of the
   * specification.
   * @required MUST be a non-empty string.
   */
  specversion: string;

  /**
   * [REQUIRED] This attribute contains a value describing the type of event
   * related to the originating occurrence. Often this attribute is used for
   * routing, observability, policy enforcement, etc. The format of this is
   * producer defined and might include information such as the version of the
   * `type` - see
   * [Versioning of Attributes in the Primer](primer.md#versioning-of-attributes)
   * for more information.
   * @required MUST be a non-empty string
   * @should SHOULD be prefixed with a reverse-DNS name. The prefixed domain dictates the
   *   organization which defines the semantics of this event type.
   * @example com.github.pull.create
   * @example com.example.object.delete.v2
   */
  type: string;

  /**
   * [OPTIONAL] Timestamp of when the occurrence happened. If the time of the
   * occurrence cannot be determined then this attribute MAY be set to some other
   * time (such as the current time) by the CloudEvents producer, however all
   * producers for the same `source` MUST be consistent in this respect. In other
   * words, either they all use the actual time of the occurrence or they all use
   * the same algorithm to determine the value used.
   * @example "2020-08-08T14:48:09.769Z"
   */
  time?: string;

  /**
   * [OPTIONAL] The event payload. This specification does not place any restriction
   * on the type of this information. It is encoded into a media format which is
   * specified by the datacontenttype attribute (e.g. application/json), and adheres
   * to the dataschema format when those respective attributes are present.
   */
  data?: Record<string, unknown | string | number | boolean> | string | number | boolean | null | unknown;

  constructor(event: any) {
    // copy the incoming event so that we can delete properties as we go
    // everything left after we have deleted know properties becomes an extension
    const properties = { ...event };

    this.id = (properties.id as string) || uuid.v4();
    delete properties.id;

    this.time = properties.time || new Date().toISOString();
    delete properties.time;

    this.type = properties.type;
    delete properties.type;

    this.specversion = properties.specversion || '1.0';
    delete properties.specversion;

    this.data = properties.data;
    delete properties.data;
  }
}

export function mapRouterEventToFalEvent(event: RouterEvent): FalEvent {
  let url = event.url;

  // strip query parameters
  if (url.indexOf('?') > -1) {
    url = url.substring(0, url.indexOf('?'));
  }

  // strip anchors
  if (url.indexOf('#') > -1) {
    url = url.substring(0, url.indexOf('#'));
  }

  // determine active page route
  let type = 'LocationDetails';
  if (url === '/') {
    type = 'SearchLanding';
  } else if (url.length === 2 || (url.length >= 3 && url[2] === '/')) {
    type = 'SearchResults';
  }

  return new FalEvent({
    type: FalEventTypes.RouteChanged,
    data: {
      url: event.url,
      pageType: type
    }
  });
}
