import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { ButtonModule, LoadingSpinnerModule, SortDropdownModule } from '@dignity-health/ngx-components';
import { DignityHealthComponentsModule } from '../dignity-health-components.module';
import { BadgesComponent } from './components/badges/badges.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { LegalDisclaimerComponent } from './components/legal-disclaimer/legal-disclaimer.component';
import { ProviderCardComponent } from './components/provider-card/provider-card.component';
import { FalModalComponent } from './components/fal-modal/fal-modal.component';
import { MyProvidersComponent } from './components/my-providers/my-providers.component';
import { QuestionsBillingInsuranceComponent } from './components/questions-billing-insurance/questions-billing-insurance.component';
import { RegionalHelplinesComponent } from './components/regional-helplines/regional-helplines.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { IconsClockComponent } from './icons/clock/icons-clock.component';
import { IconsFaxComponent } from './icons/fax/icons-fax.component';
import { IconsPhoneComponent } from './icons/phone/icons-phone.component';
import { IconsPinComponent } from './icons/pin/icons-pin.component';
import { IconsSearchComponent } from './icons/search/icons-search.component';
import { CombinedSearchBoxComponent } from './components/combined-search-box/combined-search-box.component';
import { FalLiteSearchBoxComponent } from './components/fal-lite-search-box/fal-lite-search-box.component';
import { GeolocationSearchBoxComponent } from './components/geolocation-search-box/geolocation-search-box.component';
import { KeywordSearchBoxComponent } from './components/keyword-search-box/keyword-search-box.component';
import { FocusedDirective } from './components/keyword-search-box/focused.directive';
import { FadComponentsComponent } from './components/fad-components/fad-components.component';
import { FiltersComponent } from './components/filters/filters.component';
import { MobileFiltersComponent } from './components/mobile-filters/mobile-filters.component';
import { FilterDialogModule } from './components/filter-dialog/filter-dialog.module';
import { AccordionModule } from './components/accordion/accordion.module';
import { FalLiteFiltersComponent } from './components/fal-lite-filters/fal-lite-filters.component';
import { ModalComponent } from './components/modal/modal.component';
import { EmergencyAlertComponent } from './components/emergency-alert/emergency-alert.component';

@NgModule({
  declarations: [
    IconsSearchComponent,
    IconsPhoneComponent,
    IconsFaxComponent,
    IconsClockComponent,
    IconsPinComponent,
    FooterComponent,
    RegionalHelplinesComponent,
    MyProvidersComponent,
    SearchBarComponent,
    QuestionsBillingInsuranceComponent,
    GoogleMapComponent,
    LegalDisclaimerComponent,
    ErrorPageComponent,
    ProviderCardComponent,
    BadgesComponent,
    LegalDisclaimerComponent,
    FalModalComponent,
    GeolocationSearchBoxComponent,
    KeywordSearchBoxComponent,
    CombinedSearchBoxComponent,
    FalLiteSearchBoxComponent,
    FocusedDirective,
    FadComponentsComponent,
    FiltersComponent,
    MobileFiltersComponent,
    FalLiteFiltersComponent,
    ModalComponent,
    EmergencyAlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    SortDropdownModule,
    FlexLayoutModule,
    LoadingSpinnerModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    DignityHealthComponentsModule,
    FilterDialogModule,
    AccordionModule
  ],
  exports: [
    FormsModule,
    ButtonModule,
    SortDropdownModule,
    LoadingSpinnerModule,
    FlexLayoutModule,
    IconsSearchComponent,
    IconsPhoneComponent,
    IconsFaxComponent,
    IconsClockComponent,
    IconsPinComponent,
    FooterComponent,
    SearchBarComponent,
    RegionalHelplinesComponent,
    MyProvidersComponent,
    QuestionsBillingInsuranceComponent,
    GoogleMapComponent,
    LegalDisclaimerComponent,
    ErrorPageComponent,
    ProviderCardComponent,
    DignityHealthComponentsModule,
    FalModalComponent,
    GeolocationSearchBoxComponent,
    KeywordSearchBoxComponent,
    CombinedSearchBoxComponent,
    FadComponentsComponent,
    FiltersComponent,
    MobileFiltersComponent,
    FalLiteSearchBoxComponent,
    FalLiteFiltersComponent,
    ModalComponent,
    EmergencyAlertComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
