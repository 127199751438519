import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes, NavigationEnd, RouterEvent } from '@angular/router';
import { falSearchResultsVanityUrlMatcher, falLocationDetailVanityUrlMatcher } from './core/util/fal-route-matcher';
import { filter } from 'rxjs/operators';
import { mapRouterEventToFalEvent } from './shared/models/fal-event';
import { EventPublisherService } from './shared/services/event-publisher/event-publisher.service';
import { LocationDetailGuard } from './core/util/guards/location-detail.guard';
import { getFalLiteSetting } from './utils/utils';

export const routes: Routes = [
  {
    path: '',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    loadChildren: () => {
      return getFalLiteSetting()
        ? import('./features/fal-lite/fal-lite.module').then((m) => m.FalLiteModule)
        : import('./features/home/home.module').then((m) => m.HomeModule);
    }
  },
  {
    matcher: falSearchResultsVanityUrlMatcher,
    loadChildren: () => import('./features/search/search.module').then((m) => m.SearchModule)
  },
  {
    matcher: falLocationDetailVanityUrlMatcher,
    loadChildren: () => import('./features/location/location.module').then((m) => m.LocationModule),
    canActivate: [LocationDetailGuard]
  },
  {
    path: '**',
    component: ErrorPageComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, private eventPublisherService: EventPublisherService) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => this.eventPublisherService.publish(mapRouterEventToFalEvent(event)));
  }
}
