/* eslint-disable rxjs/no-unsafe-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SearchResult, SearchType } from 'src/app/shared/models/v2/search';
import { v4 as uuid } from 'uuid';
import * as fromLocations from '../../location/+state';
import { SearchService } from './../../../shared/services/search/search.service';
import * as SearchActions from './search.actions';

@Injectable()
export class SearchEffects {
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.init),
      switchMap((action) => {
        if (action.searchType === SearchType.AllPins) {
          return forkJoin(this.searchService.query(action.payload), this.searchService.allMapPins(action.payload)).pipe(
            map((result) => {
              result[0].id = uuid();
              const search: SearchResult = result[0];
              fromLocations.LocationActions.loadLocations({ payload: result[0].results });
              search.locationForMapPins = result[1].results;
              if (action.payload.page) {
                return SearchActions.loadSearchNextPageSuccess({ search });
              } else {
                return SearchActions.loadSearchSuccess({ search });
              }
            }),
            catchError((error) => of(SearchActions.loadSearchFailure(error)))
          );
        } else {
          return this.searchService.query(action.payload).pipe(
            map((search) => {
              search.id = uuid();
              fromLocations.LocationActions.loadLocations({ payload: search.results });
              if (action.payload.page) {
                return SearchActions.loadSearchNextPageSuccess({ search });
              } else {
                return SearchActions.loadSearchSuccess({ search });
              }
            }),
            catchError((error) => of(SearchActions.loadSearchFailure(error)))
          );
        }
      })
    );
  });

  constructor(private readonly actions$: Actions, private searchService: SearchService, private store: Store) {}
}
