import { InjectionToken } from '@angular/core';
import { getRootElement } from './root-element';

export function hiddenContentAvailable(parentContainerSelector: string, contentLineHeightSelector: string): boolean {
  if (document.getElementsByClassName(parentContainerSelector)[0]) {
    return (
      document.getElementsByClassName(parentContainerSelector)[0].scrollHeight /
        parseInt(window.getComputedStyle(document.querySelectorAll(contentLineHeightSelector)[0]).lineHeight.slice(0, 2), 10) >
      6
    );
  }

  return false;
}

export function checkDesktopViewport(width: number) {
  return width > 767;
}

export const ROOT_ELEMENT = new InjectionToken<HTMLElement>('root-element');

export function setDialogPosition() {
  const dialogs = Array.from(document.getElementsByClassName('filter-dialog'));
  dialogs.forEach((element: any) => {
    element.style.left = 'initial';
    element.style.right = 'initial';

    if (element.getBoundingClientRect().right > document.body.clientWidth) {
      element.style.right = 0;
      element.style.left = 'initial';
    } else if (element.getBoundingClientRect().left < 0) {
      element.style.right = 'initial';
    }
  });  
}

export function getFalLiteSetting(): boolean { 
  let falLiteSetting = false;
  const root = getRootElement();
  const base = (
    root?.hasAttribute('enable-fal-lite')
      ? root?.getAttribute('enable-fal-lite')
      : root?.hasAttribute('enableFalLite')
      ? root?.getAttribute('enableFalLite')
      : root?.hasAttribute('enablefallite')
      ? root?.getAttribute('enablefallite')
      : 'false'
  )?.replace(/^\/+|\/+$/g, '');

  if (base != undefined && base != null && base.trim() != '' && (base.toLowerCase() === 'true' || base.toLowerCase() === 'false')) {
    falLiteSetting = Boolean(JSON.parse(base.toLowerCase() ?? 'false'));
  }

  // check for base url attribute
  const baseUrl = (
    root?.hasAttribute('base-url')
      ? root?.getAttribute('base-url')
      : root?.hasAttribute('baseUrl')
      ? root?.getAttribute('baseUrl')
      : root?.hasAttribute('baseurl')
      ? root?.getAttribute('baseurl')
      : ''
  )
    ?.replace(/^\/+|\/+$/g, '')
    ?.trim();

  return falLiteSetting && !baseUrl;
}
