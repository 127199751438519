import { Type } from '@angular/core';
import { AppComponent } from '../app/app.component';

export function getBaseLocation(root: HTMLElement): string {
  const base = root.getAttribute('base-url')?.replace(/^\/+|\/+$/g, '');
  return `/${base ?? ''}`;
}
export function getRootElement() {
  const csFadEl = document.body.querySelector<HTMLElement>('fal-saas');
  if (csFadEl) {
    return csFadEl;
  } else {
    const angularElement = FadAngularElements.find((e: [string, Type<any>]) => {
      return !!document.body.querySelector<HTMLElement>(e[0]);
    });
    return angularElement ? document.body.querySelector<HTMLElement>(angularElement[0]) : null;
  }
}

export const FadAngularElements: [string, Type<any>][] = [
  ['fal-saas', AppComponent] 
];
