import { EnvSetting, EnvEndpoint, EnvFeature, IEnvironmentDefinition } from '@commonspirit/ngx-environment';
import { createReducer, on } from '@ngrx/store';
import { GlobalActions } from '../action-types';

export const CORE_FEATURE_KEY = 'core';

export const initialEnvState: IEnvironmentDefinition = {
  name: 'dev',
  endpoints: Array<EnvEndpoint>(),
  features: Array<EnvFeature>(),
  settings: Array<EnvSetting>()
};

export const _environmentReducer = createReducer(
  initialEnvState,
  on(GlobalActions.loadEnvironment, (state, { environment }): IEnvironmentDefinition => environment)
);
export const reducer = (state: any, action: any) => _environmentReducer(state, action);
