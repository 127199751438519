/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input } from '@angular/core';
import { RouterService } from './../shared/services/router/router.service';
import { Router } from '@angular/router';
import { SettingService } from '@commonspirit/ngx-environment';
import { Store } from '@ngrx/store';
import { EntryComponent } from './models/entry.component';
import { TealiumService } from '@commonspirit/ngx-tealium';
import * as fromSearch from '../features/search/+state';
import { SettingKey } from '../core/models/setting-key';
import { InstanceService } from '@commonspirit/ngx-instance';
import { ConfigurationKey } from '../core/models/configuration-key';
import { EmbedSource,getEmbedSource } from '../core/util/root-element';

@Component({
  selector: 'fal-saas',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends EntryComponent {
  title = 'Find a Location';
  key: string;
  legalDisclaimerDescription: string;
  isAEMEnvironment: boolean = false;
  @Input() embedSource?: string;
  @Input() baseUrl?: string;

  constructor(
    public settingService: SettingService,
    public router: Router,
    public store: Store,
    public tealiumService: TealiumService,
    public routerService: RouterService,
    private service: InstanceService
  ) {
    super(settingService, router, tealiumService);

    this.key = settingService.getValue(SettingKey.GoogleMapsKey)?.toString() ?? '';
    if (!document.getElementById('google-maps')) {
      const mapsScript = document.createElement('script');
      mapsScript.type = 'text/javascript';
      mapsScript.id = 'google-maps';
      mapsScript.setAttribute('async', '');
      mapsScript.src = `https://maps.googleapis.com/maps/api/js?v=3.53&key=${this.key}`;
      mapsScript.addEventListener('load', () => {
        this.store.dispatch(fromSearch.SearchActions.setApiLoaded({ apiLoaded: true }));
      });
      document.head.appendChild(mapsScript);
    }
    this.legalDisclaimerDescription = (this.service.getConfiguration(ConfigurationKey.LegalDisclaimer)?.value as string) ?? '';

    const embedSource = getEmbedSource();
    if (embedSource === EmbedSource.AEM) {
      this.isAEMEnvironment = true;
    }
  }
}
