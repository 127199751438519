import { SearchResult, SearchType, SelectedGeolocation, Location } from './../../../shared/models/v2/search';
import { createAction, props } from '@ngrx/store';
import { ISearchParams } from 'src/app/shared/models/v1/location';

/**
 * Action called to tell the search page to initialize.
 * @param  {} '[SearchPage]Init'
 */
export const init = createAction('[Search Page] Init', props<{ payload: ISearchParams; searchType: SearchType }>());
/**
 * @param  {} '[Search/API]SearchStarted'
 * @param  {string}>(} props<{searchTerms
 */
export const searchStarted = createAction('[Search/API] Search Started', props<{ searchTerms: string }>());
/**
 * Action called when loading search succeeds.
 * @param  {} '[Search/API]LoadSearchSuccess'
 * @param  {Location[]}>(} props<{search
 */
export const loadSearchSuccess = createAction('[Search/API] Load Search Success', props<{ search: SearchResult }>());
/**
 * Action for if loading a search fails.
 * @param  {} '[Search/API]LoadSearchFailure'
 * @param  {error: any}
 */
export const loadSearchFailure = createAction('[Search/API] Load Search Failure', props<{ error: any }>());
/**
 * @param  {} '[Search/API]UICardViewToggled'
 * @param  {} ;exportconstsetMapCenter=createAction('[Search/API]SetMapCenter'
 * @param  {{lat:number;lng:number}}>(} props<{mapCenter
 */
export const toggleCardView = createAction('[Search/API] UI Card View Toggled');
/**
 * @param  {} '[Search/API]SetMapCenter'
 * @param  {{lat:number;lng:number}}>(} props<{mapCenter
 */
export const setMapCenter = createAction('[Search/API] Set Map Center', props<{ mapCenter: { lat: number; lng: number } }>());
/**
 * @param  {} '[Search/API]SetMapZoom'
 * @param  {number}>(} props<{mapZoom
 */
export const setMapZoom = createAction('[Search/API] Set Map Zoom', props<{ mapZoom: number }>());
/**
 * @param  {} '[Search/API]SetAPILoaded'
 * @param  {boolean}>(} props<{apiLoaded
 */
export const setApiLoaded = createAction('[Search/API] Set API Loaded', props<{ apiLoaded: boolean }>());

/**
 * @param  {} '[Search/API]loadSearchNextPageSuccess'
 * @param  {boolean}>(} props<{ISearchParams
 */
export const loadSearchNextPageSuccess = createAction('[Search/API] load Search Next Page Success', props<{ search: SearchResult }>());

/**
 * @param  {} '[Search/API]SetSelectedGeolocation'
 * @param  {SelectedGeolocation}>(} props<{selectedGeolocation
 */
export const setSelectedGeolocation = createAction(
  '[Search/API] Set Selected Geolocation',
  props<{ selectedGeolocation: SelectedGeolocation | null }>()
);

export const searchStateReset = createAction('[Search/API] Search State Reset');
