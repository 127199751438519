import { createAction, props } from '@ngrx/store';
import { FALLiteSelectedFilters } from 'src/app/shared/models/v2/search';

export const loadFalLite = createAction('[FalLite] Load FalLite');

export const loadFalLiteSuccess = createAction('[FalLite] Load FalLite Success', props<{ data: any }>());

export const loadFalLiteFailure = createAction('[FalLite] Load FalLite Failure', props<{ error: any }>());

export const setFalLiteSelectedFilters = createAction(
  '[FalLite] Selected filters on Landing page',
  props<{ selectedFilters: FALLiteSelectedFilters }>()
);
export const selectedFilterStateReset = createAction('[FalLite] Selected filters State Reset');
