import { InstConfigurationKey } from '@commonspirit/ngx-instance';

export abstract class ConfigurationKey extends InstConfigurationKey {
  public static readonly QuestionsBillingAndInsurance = 'shared.questions.billingAndInsurance';
  public static readonly RegionalHelplinesHeading = 'shared.regionalHelplines.heading';
  public static readonly RegionalHelplines = 'shared.regionalHelplines.phoneNumbers';
  public static readonly MyProviders = 'shared.myProviders';
  public static readonly EmergencyUrgentCareInfo = 'shared.emergencyUrgentCareInfo';
  public static readonly EmergencyDetail = 'shared.emergencyUrgentCareInfo.emergency';
  public static readonly UrgentCareDetail = 'shared.emergencyUrgentCareInfo.urgentCare';
  public static readonly SearchHomeDescription = 'shared.searchHome.description';
  public static readonly ShowERInfo = 'shared.UI.showERInfo';
  public static readonly LegalDisclaimer = 'shared.legal.disclaimer';
  public static readonly ErrorPageLinks = 'shared.errorPage.links';

  public static readonly SortOptions = 'shared.sort.options';
  public static readonly SortDefault = 'shared.sort.default';
  public static readonly ShowSort = 'shared.UI.showSortOptions';
  public static readonly ShowSearchButton = 'searchButton';

  public static readonly FadPage = 'shared.fadPage';
  public static readonly FalPage = 'shared.falPage';
  public static readonly MarketCodeImage = 'shared.marketCodeImage';
  public static readonly ConditionallyViewProviders = 'shared.conditionallyShowProviders';
  public static readonly DynamicLocationFadPage = 'shared.dynamicLocFadPage';
  public static readonly Filters = 'shared.filters';
  public static readonly SearchRadiusFilter = 'shared.filters.searchradius';
  public static readonly LocationsIanaTimeZoneName = 'shared.locations.IanaTimeZoneName';
  public static readonly FadMaleImageUrl = 'shared.fadMaleImageUrl';
  public static readonly fadFemaleImageUrl = 'shared.fadFemaleImageUrl';
  public static readonly FadconfigurationUrl = 'shared.fadConfigurationUrl';
  public static readonly FadSaasjs = 'shared.fadSaasjs';
  public static readonly DivisionCodes = 'shared.divisionCodes';
  public static readonly MarketCodes = 'shared.marketCodes';
  public static readonly LocationTypes = 'shared.filters.LocationTypes';
  public static readonly Specialities = 'shared.filters.Specialities';
  public static readonly HospitalBasedLocation = 'shared.hospitalBasedLocation';
  public static readonly EmergencyText = 'shared.emergencyText';
  public static readonly SideNavigationLocationIds = 'shared.externalCodesForNavigationContent';
  public static readonly LocationNameUrlSegments = 'shared.locationNameUrlSegments';
  public static readonly AemRedirectPage = 'shared.aemRedirectPage';
  public static readonly VmfhBaseUrl = 'shared.vmfhBaseUrl';
  public static readonly NavigationContentUrl = 'shared.navigationContentUrl';

  public static readonly LocationAutoSuggSearchURLParams = 'shared.locationsearch.params';
  public static readonly LoadingSuggestion = 'shared.loadingSuggestion';
  public static readonly LinkCanonicals = 'shared.linkCanonicals';
  public static readonly ShortcutIcon = 'shared.shortcutIcon';
  public static readonly LandingFilters = 'shared.landingFilters';
  public static readonly FuzzyMatch = 'shared.isFuzzyMatch';
}
