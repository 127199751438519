import { createReducer, on } from '@ngrx/store';
import * as HomeActions from './home.actions';

export const homeFeatureKey = 'home';

export interface HomeState {
  activeFilters: boolean[];
}

export const initialState: HomeState = {
  activeFilters: []
};

export const homeReducer = createReducer(
  initialState,
  on(HomeActions.loadHome, (state): HomeState => state),
  on(HomeActions.loadHomeSuccess, (state): HomeState => state),
  on(HomeActions.loadHomeFailure, (state): HomeState => state)
);
