import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromStore from './location.reducer';

export const selectLocationState = createFeatureSelector<fromStore.LocationState>(fromStore.LOCATION_FEATURE_KEY);

export const isLoading = createSelector(selectLocationState, fromStore.selectIsLoading);
export const error = createSelector(selectLocationState, fromStore.selectError);

export const getSelectedLocation = createSelector(selectLocationState, fromStore.selectedLocation);
export const getProvidersBySelectedLocation = createSelector(selectLocationState, fromStore.selectprovidersBySelectedLocation);
