import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FaLLiteEffects } from './falLite.effects';
import * as fromFalLite from './falLite.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromFalLite.falliteFeatureKey, fromFalLite.falLiteReducer),
    EffectsModule.forFeature([FaLLiteEffects])
  ],
  declarations: []
})
export class FalLiteStateModule {}
