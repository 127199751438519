import {
  CSH_ENVIRONMENT_SCRIPT_FILENAME,
  EnvironmentDefinition,
  EnvironmentInitializerService,
  EnvironmentService
} from '@commonspirit/ngx-environment';
import { CSH_TEALIUM_CONFIGURATION, TealiumConfiguration } from '@commonspirit/ngx-tealium';
import * as fromCore from './+state';
import { Store } from '@ngrx/store';
import {
  CSH_INSTANCE_DEFINITION,
  CSH_INSTANCE_DEFINITION_URL,
  CSH_INSTANCE_ROOT_COMPONENT,
  getRootComponentBySelector,
  InstanceDefinition,
  InstanceInitializerService,
  provideRootComponentConfigurationUrlAsInstanceDefinitionUrl
} from '@commonspirit/ngx-instance';
import { tap } from 'rxjs/operators';
import { APP_BOOTSTRAP_LISTENER, APP_ID, APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ENV, getEnv } from '../../environments/environment.provider';
import { IEnvironment } from '../../environments/ienvironment';
import { MULESOFT_LOCATIONS_API_V1_BASE_URL } from '../shared/services/nswag/locations.service';
import { SettingKey } from './models/setting-key';
import { ConfigurationKey } from './models/configuration-key';
import { EmbedSource, getEmbedSource } from './util/root-element';

export const appEnvironmentInitializer = (
  environmentInitializerService: EnvironmentInitializerService,
  store: Store
): (() => Promise<EnvironmentDefinition>) => {
  return () =>
    environmentInitializerService
      .initialize()
      .pipe(
        tap((env) => {
          store.dispatch(fromCore.CoreActions.loadEnvironment({ environment: env }));
        })
      )
      .toPromise();
};

export const getTealiumConfig = (environmentService: EnvironmentService): TealiumConfiguration | undefined => {
  const config = (environmentService.getSetting(SettingKey.Tealium)?.value ?? {}) as { account: string; profile: string };
  const envName = environmentService.getName().toLowerCase();
  const tealiumEnvName =
    envName === 'dev'
      ? 'dev'
      : envName === 'qa' || envName === 'stg' || envName === 'stage'
      ? 'qa'
      : envName === 'prd' || envName === 'prod'
      ? 'prod'
      : 'dev'; // default to dev
  if (getEmbedSource() === EmbedSource.AEM) {
    return new TealiumConfiguration(tealiumEnvName, config.profile, config.account, false, false);
  } else {
    return new TealiumConfiguration(tealiumEnvName, config.profile, config.account, true, true);
  }
};

export const appInstanceInitializer = (
  instanceInitializerService: InstanceInitializerService,
  document: Document,
  appId: string
): (() => Promise<InstanceDefinition>) => {
  return () =>
    instanceInitializerService
      .initialize()
      .pipe(
        tap((inst: InstanceDefinition) => {
          instanceConfig = inst;
          const color = inst.configurations.find((x) => x.key === ConfigurationKey.ThemeColorPalette)?.value as string;
          if (!!color && !(color.startsWith('__') && color.endsWith('__'))) {
            const instanceType = getEmbedSource();
            const root = document.querySelector('html');
            root?.classList.add(color);

            const fontList = Array<string>();
            if (color === 'dhcl-dignity-health') {
              fontList.push('https://use.typekit.net/dfg1mni.css');
            } else if (color === 'dhcl-chi-health') {
              fontList.push('https://use.typekit.net/org3xhj.css');
            } else if (color === 'dhcl-virginia-mason') {
              fontList.push('https://use.typekit.net/dfg1mni.css');
              if (instanceType != 'aem') {
                fontList.push('../assets/icomoon.css');
              }
            }

            fontList.forEach((element) => {
              const font = document.createElement('link');
              font.id = `fal-saas-${appId}-fonts-${color}`;
              font.rel = 'stylesheet';
              font.href = `${element}?display=swap`;
              font.type = 'text/css';
              document.head.appendChild(font);
            });
          }
          const googleCluster = document.createElement('script');
          googleCluster.type = 'text/javascript';
          googleCluster.async = true;
          googleCluster.src = 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js';
          document.head.appendChild(googleCluster);
        })
      )
      .toPromise();
};

export const appStylesInitializer = (document: Document, appId: string, env: IEnvironment): (() => boolean) => {
  if (env.production) {
    const style = document.createElement('link');
    style.id = `fal-saas-${appId}-styles-main`;
    style.rel = 'stylesheet';
    style.href = `${env.deployUrl}styles.css`;
    style.type = 'text/css';

    document.head.appendChild(style);
  }
  return () => true;
};

export const getEnvironmentEndpoint = (environmentService: EnvironmentService): string => {
  return environmentService.getEndpoint('mulesoftLocationsApi')?.url ?? 'api';
};

let instanceConfig: InstanceDefinition;

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: CSH_ENVIRONMENT_SCRIPT_FILENAME,
      useValue: 'fal-saas.js'
    },
    {
      provide: MULESOFT_LOCATIONS_API_V1_BASE_URL,
      useFactory: getEnvironmentEndpoint,
      deps: [EnvironmentService]
    },
    {
      provide: ENV,
      useFactory: getEnv
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appStylesInitializer,
      deps: [DOCUMENT, APP_ID, ENV],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appEnvironmentInitializer,
      deps: [EnvironmentInitializerService, Store],
      multi: true
    },
    {
      provide: CSH_INSTANCE_ROOT_COMPONENT,
      useFactory: () => getRootComponentBySelector('fal-saas')
    },
    provideRootComponentConfigurationUrlAsInstanceDefinitionUrl,
    {
      provide: APP_INITIALIZER,
      useFactory: appInstanceInitializer,
      deps: [InstanceInitializerService, DOCUMENT, APP_ID, CSH_INSTANCE_ROOT_COMPONENT, CSH_INSTANCE_DEFINITION_URL],
      multi: true
    },
    {
      provide: CSH_INSTANCE_DEFINITION,
      useFactory: () => {
        return instanceConfig;
      },
      deps: [APP_BOOTSTRAP_LISTENER]
    },
    {
      provide: CSH_TEALIUM_CONFIGURATION,
      useFactory: getTealiumConfig,
      deps: [EnvironmentService]
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
