import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationEffects } from './location.effects';
import * as fromLocation from './location.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLocation.LOCATION_FEATURE_KEY, fromLocation.locationReducer),
    EffectsModule.forFeature([LocationEffects])
  ],
  declarations: []
})
export class LocationStateModule {}
