import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SearchEffects } from './search.effects';
import * as fromSearch from './search.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSearch.SEARCH_FEATURE_KEY, fromSearch.reducer),
    EffectsModule.forFeature([SearchEffects])
  ],
  declarations: []
})
export class SearchStateModule {}
