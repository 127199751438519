import { IEnvironmentDefinition } from '@commonspirit/ngx-environment';
//import { hydrationMetaReducer } from './hydration/hydration.reducer';
import { hydrationMetaReducer } from './hydration/hydration.reducer';
import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { _environmentReducer } from './core/+state';

export interface State {
  environment: IEnvironmentDefinition;
  router: fromRouter.RouterReducerState<any>;
}

export const reducers: ActionReducerMap<State> = {
  environment: _environmentReducer,
  router: fromRouter.routerReducer
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
