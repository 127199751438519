import { IBaseEnvironment } from './ienvironment';

interface IMockEnvironment extends IBaseEnvironment {
  endpointURL: string;
  enableFilters: boolean;
  useMockEnvironment: boolean;
}

export const MockEnvironment: IMockEnvironment = {
  endpointURL: '../assets/',
  useMockEnvironment: true,
  enableFilters: false,
  production: false
};
