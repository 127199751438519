import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { InstanceService } from '@commonspirit/ngx-instance';
import { ErrorPageLink } from '../../../core/models/error-page-link';
import { ConfigurationKey } from '../../../core/models/configuration-key';
import { EnvValue } from '@commonspirit/ngx-environment/lib/models/key-value-pair';
import { EmbedSource, getEmbedSource } from 'src/app/core/util/root-element';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'fal-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPageComponent {
  @Input() errorPageLinks?: ErrorPageLink[];

  constructor(public instanceService: InstanceService, private metaService: Meta, private titleService: Title) {
    this.setTitleMetaTags();
    this.setErrorPageLinks();
  }

  setTitleMetaTags(): void {
    const marketCode = this.instanceService.getConfiguration(ConfigurationKey.MarketCodes)?.value as string;
    this.metaService.addTag({ name: 'robots', content: 'noindex,nofollow' });
    this.metaService.addTag({ name: 'googlebot', content: 'noindex,nofollow' });
    this.titleService.setTitle(`Error Page | ${marketCode}`);
  }

  setErrorPageLinks(): void {
    let embedSource = '';
    //Error links only differ in AEM.
    if (getEmbedSource() === EmbedSource.AEM) {
      embedSource = getEmbedSource() ?? '';
    }
    this.errorPageLinks =
      (this.getSourceInstanceConfiguration(ConfigurationKey.ErrorPageLinks, embedSource)?.value as {
        text: string;
        href: string;
        target: string | undefined;
      }[]) ?? [];
  }

  getSourceInstanceConfiguration(key: string, source: string): { embedSource: string; value: EnvValue } | undefined {
    const returnVaule =
      (this.instanceService.getConfiguration(key)?.value as {
        embedSource: string;
        value: EnvValue;
      }[]) ?? [];

    return returnVaule.find((x) => x.embedSource === source);
  }
}
