import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SEARCH_FEATURE_KEY, SearchState, searchAdapter } from './search.reducer';

export const getSearchState = createFeatureSelector<SearchState>(SEARCH_FEATURE_KEY);

const { selectAll, selectEntities } = searchAdapter.getSelectors();

export const getSearchLoaded = createSelector(getSearchState, (state: SearchState) => state.loaded);

export const getSearchError = createSelector(getSearchState, (state: SearchState) => state.error);

export const getSearchResults = createSelector(getSearchState, (state: SearchState) => state.results);

export const getAllSearch = createSelector(getSearchState, (state: SearchState) => selectAll(state));

export const getSearchEntities = createSelector(getSearchState, (state: SearchState) => selectEntities(state));

export const selectedId = createSelector(getSearchState, (state: SearchState) => state.id);

export const getApiLoaded = createSelector(getSearchState, (state: SearchState) => state.apiLoaded);

export const getCurrentSearch = createSelector(getSearchState, (state: SearchState) => selectAll(state).slice(-1)[0]);

export const getCurrentUI = createSelector(getSearchState, (state: SearchState) => state.UI);

export const getFilters = createSelector(getSearchState, (state: SearchState) => selectAll(state)[0].filters);

export const getSelected = createSelector(getSearchEntities, selectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);

export const getAllSearchIds = createSelector(getSearchState, (state: SearchState) => state.ids);

export const getNextPageSearch = createSelector(getSearchState, (state: SearchState) => state.pageSearchResult);

export const getSelectedGeolocation = createSelector(getSearchState, (state: SearchState) => state.selectedGeolocation);

export const getToggleCardView = createSelector(getSearchState, (state: SearchState) => state.UI.isCardView);

//export const getLocationForPins = createSelector(getSearchState, (state: SearchState) => state.locationsForPins);