import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as LocationActions from './location.actions';
import { AssociatedProvider, Location } from './../../../shared/models/v2/search';

export const LOCATION_FEATURE_KEY = 'location';

export interface LocationState extends EntityState<Location> {
  isLoading: boolean;
  error: string | null;
  selectedLocation: Location | undefined;
  routedFrom: string;
  associatedProviders: AssociatedProvider[] | undefined;
}

export interface PartialState {
  readonly [LOCATION_FEATURE_KEY]: LocationState;
}

export const adapter: EntityAdapter<Location> = createEntityAdapter<Location>();

export const locationAdapter: EntityAdapter<Location> = createEntityAdapter({
  selectId: (location) => location.id
});

export const initialState: LocationState = locationAdapter.getInitialState({
  isLoading: false,
  error: null,
  selectedLocation: undefined,
  routedFrom: '',
  associatedProviders: undefined
});

export const locationReducer = createReducer(
  initialState,
  on(LocationActions.setSelectedLocation, (state, { payload }): LocationState => {
    return { ...state, selectedLocation: payload };
  }),
  on(LocationActions.setProvidersBySelectedLocation, (state, { providers }): LocationState => {
    return { ...state, associatedProviders: providers };
  }),
  on(LocationActions.loadLocations, (state, { payload }) => {
    adapter.removeAll(state);
    return adapter.addMany(payload, { ...state, selectLocationId: null });
  }),
  on(LocationActions.setRoutedFrom, (state, { routedFrom }): LocationState => {
    return { ...state, routedFrom };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectIsLoading = (state: LocationState): boolean => state.isLoading;
export const selectError = (state: LocationState): string | null => state.error;

export const selectedLocation = (state: LocationState): Location | undefined => state.selectedLocation;
export const selectprovidersBySelectedLocation = (state: LocationState): AssociatedProvider[] | undefined => state.associatedProviders;

export function reducer(state: LocationState, action: Action): LocationState {
  return locationReducer(state, action);
}
