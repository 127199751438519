<div class="error-container">
  <div class="main-content">
    <h1>Sorry, something went wrong</h1>
    <svg class="error-image" viewBox="0 0 335 251" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M141.764 42.7039C145.436 38.7554 149.661 35.2184 152.078 33.2946L156.231 34.6086L286.258 75.7908C287.318 79.3701 288.021 83.5999 288.468 87.0995L141.764 42.7039ZM274.976 51.0708C277.147 54.4631 279.578 59.5109 281.614 64.1244L230.839 48.0428L210.23 41.5168L274.976 51.0708ZM294.796 70.2488L294.791 70.2406C294.791 70.2406 294.791 70.239 294.791 70.2374C294.501 69.4747 287.657 51.5066 281.825 43.7138C281.048 42.6747 279.88 41.9819 278.581 41.79L151.822 23.0853C151.744 23.0739 151.669 23.0804 151.591 23.0739C151.473 23.0609 151.356 23.0577 151.237 23.0528C150.978 23.0447 150.725 23.0577 150.474 23.0886C150.368 23.1016 150.264 23.1113 150.16 23.1308C149.853 23.1894 149.554 23.2739 149.264 23.3878C149.222 23.404 149.178 23.4122 149.136 23.4301C148.81 23.5683 148.506 23.7456 148.22 23.9472C148.193 23.9683 148.158 23.9781 148.129 23.9992C147.618 24.3765 135.55 33.3142 129.293 42.5641C128.429 43.8374 128.231 45.4392 128.754 46.8801C129.278 48.3225 130.462 49.4381 131.952 49.8869L292.638 98.514C293.114 98.6571 293.604 98.7286 294.09 98.7286C295.179 98.7286 296.254 98.3741 297.134 97.696C298.406 96.7203 299.11 95.19 299.021 93.6061C298.986 93.0222 298.167 79.2222 294.796 70.2488Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M209.106 190.299C169.813 163.434 130.01 190.027 129.613 190.299C127.392 191.818 126.824 194.844 128.337 197.069C129.852 199.293 132.885 199.874 135.112 198.358C136.517 197.409 169.899 175.313 203.598 198.353C204.44 198.931 205.4 199.206 206.348 199.206C207.904 199.206 209.434 198.462 210.379 197.08C211.9 194.855 211.331 191.821 209.106 190.299Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M257.725 23.4565C257.725 20.7667 259.905 18.5844 262.597 18.5844C265.287 18.5844 267.467 20.7667 267.467 23.4565"
      />
      <mask id="mask0_2268:22181" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="334" height="196">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 54.1038H333.536V249.755H0V54.1038Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2268:22181)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M304.806 107.897C303.438 107.066 301.511 106.533 299.485 107.922C299.395 107.979 299.311 108.04 299.225 108.102C299.158 108.153 299.093 108.187 299.026 108.242C298.943 108.312 298.877 108.384 298.8 108.453C298.73 108.518 298.659 108.579 298.592 108.649C297.088 110.133 296.982 111.701 297.317 112.965C297.556 114.192 298.263 115.248 299.238 115.958C314.708 129.303 323.779 148.731 323.779 169.393C323.779 208.326 292.105 239.998 253.173 239.998H70.6977C37.0936 239.998 9.75533 212.66 9.75533 179.057C9.75533 152.751 26.5248 129.51 51.4856 121.221L54.9803 120.061L54.8226 116.384C54.7917 115.656 54.7673 114.924 54.7673 114.187C54.7673 86.4378 77.3424 63.861 105.094 63.861C109.587 63.861 114.007 64.4644 118.3 65.6352C118.315 65.6385 118.32 65.6352 118.334 65.6385C120.125 66.0987 124.129 66.5524 125.508 62.7438C126.887 58.9385 123.486 57.1399 122.671 56.7301C121.858 56.3203 111.091 54.1038 105.094 54.1038C72.2979 54.1038 45.5564 80.5151 45.0181 113.184C17.9466 123.719 -0.00195312 149.75 -0.00195312 179.057C-0.00195312 218.039 31.7141 249.755 70.6977 249.755H253.173C297.486 249.755 333.536 213.705 333.536 169.393C333.536 145.523 322.901 123.097 304.806 107.897Z"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M170.129 14.4105C170.129 14.4105 170.571 15.1959 171.512 15.8302C171.514 15.8318 171.516 15.8318 171.516 15.8334C171.732 15.9782 171.965 16.118 172.235 16.2351L172.264 16.2384C172.872 16.5099 173.539 16.6677 174.246 16.6677C174.989 16.6677 175.625 16.3424 176.316 16.1912C176.346 16.1814 176.372 16.1766 176.399 16.1652C176.711 16.0497 177.001 15.9619 177.276 15.8887C187.02 11.937 197.571 9.75789 208.47 9.75789C227.235 9.75789 244.551 16.0074 258.504 26.5046L258.505 26.4835C258.505 26.4835 258.648 26.612 258.9 26.799C258.907 26.8039 258.915 26.8087 258.923 26.8152C260.065 27.656 263.346 29.6107 266.14 26.7957C269.142 23.771 266.677 20.7186 265.994 19.9851C250.14 7.49095 230.175 0.000610352 208.47 0.000610352C196.174 0.000610352 184.275 2.48709 173.301 6.98845C171.153 7.7544 169.359 9.405 169.359 11.7793C169.359 12.7062 169.633 13.5649 170.081 14.3048C170.098 14.3389 170.111 14.3747 170.129 14.4105Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M223.246 136.81C210.201 136.81 200.425 125.812 200.011 125.339C198.238 123.311 198.443 120.229 200.471 118.455C202.494 116.682 205.569 116.886 207.347 118.904C207.507 119.084 214.726 127.053 223.243 127.053C223.35 127.053 223.457 127.051 223.566 127.048C228.271 126.937 232.771 124.251 236.944 119.068C238.635 116.97 241.705 116.637 243.805 118.327C245.902 120.016 246.234 123.088 244.545 125.186C238.467 132.736 231.462 136.644 223.721 136.805C223.563 136.808 223.404 136.81 223.246 136.81Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M117.541 136.81C104.495 136.81 94.7203 125.812 94.3056 125.339C92.533 123.311 92.7379 120.229 94.7658 118.455C96.7888 116.682 99.8656 116.886 101.641 118.904C101.802 119.084 109.021 127.053 117.538 127.053C117.645 127.053 117.752 127.051 117.861 127.048C122.566 126.937 127.066 124.251 131.239 119.068C132.928 116.97 136 116.637 138.1 118.327C140.197 120.016 140.529 123.088 138.839 125.186C132.762 132.736 125.757 136.644 118.016 136.805C117.858 136.808 117.699 136.81 117.541 136.81Z"
      />
    </svg>
    <div *ngIf="errorPageLinks && errorPageLinks.length > 0">
      <h2 class="links-heading">Here are some helpful links instead:</h2>
      <ul class="links-list">
        <li *ngFor="let errorPageLink of errorPageLinks">
          <p>
            <a class="error-link" href="{{errorPageLink.href}}" target="{{errorPageLink?.target ?? '_self'}}">{{errorPageLink.text}}</a>
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
